<template>
  <div class="navigation">
    <div class="navigation__content">
      <div class="logo"><img :src="require(`@/assets/images/logoBlue.png`)" alt="logo" /></div>
      <ul class="menu">
        <li class="menu__item" v-for="(route, id) in items" :key="`menu_item-${id}`">
          <router-link
            exact
            custom
            class="menu__item_link"
            :to="route.path"
            v-slot="{ navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[
                route.active && 'menu__item_link--active',
                isExactActive && 'menu__item_link--exact-active',
              ]"
            >
              <span class="menu__item_icon"><component :is="route.iconName" /></span>
              <p class="menu__item_text">{{ route.title }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="profile">
      <div class="profile__image">
        {{ user.initials }}
      </div>
      <div class="profile__text">
        <h6>{{ user.name_abbreviation }}</h6>
        <p>{{ user.role }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import iconCollection from "@/components/icons/IconCollection.vue";
import iconContent from "@/components/icons/IconContent.vue";
export default {
  components: { iconCollection, iconContent },
  data() {
    return {
      items: [
        { id: 2, title: "Collection", path: "/admin/collection", iconName: "iconCollection" },
        { id: 3, title: "Content", path: "/admin/content", iconName: "iconContent" },
      ],
    };
  },
  created() {
    const { fullPath } = this.$route;
    const mainPath = fullPath.split("/")[2];
    this.items = this.items.map(m => ({
      ...m,
      active: mainPath === m.path.split("/")[2],
    }));
  },
  computed: {
    ...mapGetters("user", { user: "getUserInfo" }),
  },
  watch: {
    $route(to) {
      const { fullPath } = to;
      const name = fullPath.substring(1).split("/")[1];
      const items = [];

      this.items = this.items.map(m => {
        let path = m.path.split("/")[2];
        if (path === `${name}`) {
          items.push({ ...m, active: true });
        } else {
          items.push({ ...m, active: false });
        }
      });

      this.items = items;
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 20rem;
  padding: 1rem 1rem 4.5rem 4rem;
  overflow: hidden;
  &__content {
    .logo {
      display: block;
      margin-bottom: 4rem;
    }
    .menu {
      &__item {
        margin-bottom: 2.6rem;
        cursor: pointer;
        &_icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
          color: $black;
        }
        &_text {
          @include type();
          color: $black;
          transition: all 0.3s ease-in-out;
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
        }
        &_link {
          display: flex;
          align-items: center;
          ::v-deep g {
            fill: $black;
            transition: all 0.3s ease-in-out;
          }
          &--active,
          &:hover {
            .menu__item_text {
              color: $orange;
            }
            ::v-deep g {
              fill: $orange;
            }
          }
        }
      }
    }
  }
  .profile {
    display: flex;
    align-items: center;
    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      background-color: $orange;
      font-size: 2rem;
      font-weight: 600;
      color: $white;
      margin-right: 11px;
    }
    &__text {
      h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        text-transform: capitalize;
        margin-bottom: 2px;
      }
      p {
        font-size: 12px;
        line-height: 1.25;
        color: rgba($color: #111111, $alpha: 0.5);
        text-transform: capitalize;
      }
    }
  }
}
</style>