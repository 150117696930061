<template>
  <div class="grotta-home-book">
    <div class="grotta-home-book__header">
      <h1 class="grotta-home-book__header_title" align="center">
        The Grotta Home by Richard Meier
      </h1>
      <span class="grotta-home-book__header_author">by arnoldsche</span>
      <ShareBar class="grotta-home-book__header_share" />
      <BaseButton class="button--oval" text="Buy Now" />
    </div>

    <div class="grotta-home-book__body">
      <img
        class="main-image"
        src="@/assets/images/history/arnoldsche-cover.png"
        alt="The Grotta Home by Richard Meier"
      />

      <section class="reviews reviews__container">
        <h2 class="reviews__title">Reviews</h2>
        <span class="reviews__description"
          >"The book, “The Grotta Home" is an intimate portrait of the union of two individuals,
          fused into a singular point of view and seen through the lens of the artifacts that have
          defined their passionate journey. The house itself is a vessel that is teeming with their
          narrative. Meier’s building deploys lightness in a way which warms the repository and
          balances it between the experiences of living with and dreaming through the objects it
          encapsulates."</span
        >
        <span class="reviews__author">Josh Owen, Josh Owen LLC</span>
        <BaseButton class="button--oval" text="All Reviews" />
      </section>

      <section class="explore explore__container">
        <div class="explore__group">
          <div class="explore__group_title-box">
            <h2 class="title-box__title">Explore the book</h2>
            <BaseButton
              class="button--oval"
              text="Preview Online"
              @click.native="showPreview = true"
            />
          </div>
          <img
            class="explore__group__main-image"
            src="@/assets/images/history/arnoldsche-cover.png"
            alt="The Grotta Home by Richard Meier"
          />
        </div>
        <span class="explore__description"
          >A ‘vessel for living’ Glenn Adamson called the remarkable residence Richard Meier
          designed to house Sandra and Louis Grotta’s collection of contemporary studio jewellery
          and significant works in wood, ceramic and fiber. The building was conceived around the
          collection, framing the objects within the open architecture, which comprises an equal
          blend of glass and concrete. Nature, visible from many vantage points, plays an essential
          supporting role. The Grotta Home by Richard Meier: A Marriage of Architecture and Craft is
          rich in photographs of the collection and provides impressive insights into this
          exceptionally personal project by Richard Meier. The accompanying essays afford the reader
          a greater sense of how the Grottas have not simply acquired art but have immersed
          themselves in it.</span
        >
        <div class="explore__properties">
          <span>336 pp</span>
          <span>28 x 30cm</span>
          <span>approx. 300ills</span>
          <span>hardcover</span>
          <span>English</span>
        </div>
        <BaseButton class="button--oval" text="Buy Now" />
      </section>

      <section class="creators creators__container">
        <h2 class="creators__title">Related Creators</h2>
        <div class="creators__group">
          <span v-for="(creator, index) in creators" :key="index" class="creators__group_item">{{
            creator
          }}</span>
        </div>
      </section>
    </div>

    <div class="grotta-home-book__pop-up" :class="{ visible: showPreview }">
      <div class="grotta-home-book__pop-up_header">
        <BaseButton
          class="button--oval button--outline-white"
          text="Exit preview"
          @click.native="showPreview = false"
        />
      </div>

      <div class="pdf__wrapper">
        <pdf
          class="pdf"
          src="./test.pdf"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
        ></pdf>
        <div class="controls-container">
          <BaseButton
            class="button--oval button--outline-white"
            text="Prev"
            @click.native="currentPage--"
          />
          {{ currentPage }} / {{ pageCount }}
          <BaseButton
            class="button--oval button--outline-white"
            text="Next"
            @click.native="currentPage++"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import ShareBar from "@/components/Share.vue";
import pdf from "vue-pdf";

export default {
  components: { BaseButton, ShareBar, pdf },

  data() {
    return {
      showPreview: false,
      currentPage: 1,
      pageCount: 0,

      creators: [
        "Accorsi, William",
        "Barnes, Dorothy Gill",
        "Birkkjær, Birgit",
        "Brennan, Sara",
        "Di Mare, Dominic",
        "Giles, Mary",
        "Hernmarck, Helena",
        "Hildebrandt, Marion",
        "Hucker, Thomas",
        "Iwata, Kiyomi",
        "Jacobs, Ferne",
        "Jacques, Stéphanie",
        "Kawata, Tamiko",
        "Kobayashi, Naomi",
        "Kosonen, Markku",
        "Krejci, Luba",
        "Kulka, Lilla",
        "Kumai, Kyoko",
        "Laky, Gyöngy",
        "Lønning, Kari",
        "MacNutt, Dawn",
        "McQueen, John",
        "Merkel-Hess, Mary",
        "Minkowitz, Norma",
        "Mulford, Judy",
        "Niehues, Leon and Sharon",
        "Rossbach, Ed",
        "Rousseau-Vermette, Mariette",
        "Russmeyer, Axel",
        "Sachs, Debra",
        "Sekijima, Hisako",
        "Sekimachi, Kay",
        "Seventy, Sylvia",
        "Shindo, Hiroyuki",
        "Sisson, Karyl",
        "So, Jin-Sook",
        "Stoyanov, Aleksandra",
        "Tanaka, Chiyoko",
        "Tanaka, Hideho",
        "Valoma, Deborah",
        "Westphal, Katherine",
        "Yeonsoon, Chang",
        "Yoshida, Masako",
      ],
    };
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.grotta-home-book {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $black;
  padding: 20rem 5rem 17rem;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.8rem;
    line-height: 4.4rem;

    &_title {
      width: 30%;
      font-size: 6.2rem;
      font-weight: 400;
      line-height: 6.8rem;
      font-family: $fontBodoni;
    }

    &_share {
      margin-top: 2.5rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image {
      margin-top: 9.2rem;
      width: 75%;
    }

    .reviews {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 13.8rem;
      }

      &__title {
        font-size: 6.2rem;
        font-weight: 500;
        line-height: 6.8rem;
        font-family: $fontBodoni;
      }

      &__description {
        width: 72%;
        font-size: 2.1rem;
        line-height: 3.5rem;
        margin-top: 4rem;
        display: inline-block;
        text-align: center;
      }

      &__author {
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-family: $fontBodoni;
      }

      .button--oval {
        margin-top: 6.7rem;
      }
    }

    .explore {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 17.6rem;
      }

      &__group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_title-box {
          display: flex;
          flex-direction: column;
          margin-left: 11.5rem;
          width: 50%;

          .title-box__title {
            font-size: 6.2rem;
            font-weight: 400;
            line-height: 6.8rem;
            font-family: $fontBodoni;
          }
        }

        &__main-image {
          height: 100%;
          width: 45%;
        }
      }

      &__description {
        font-size: 2.1rem;
        line-height: 3.5rem;
        display: inline-block;
        text-align: center;
        margin-top: 16.3rem;
        width: 90%;
      }

      &__properties {
        display: flex;
        justify-content: space-between;
        font-size: 2.1rem;
        line-height: 3.5rem;
        margin-top: 5rem;
        width: 50%;
      }
    }

    .creators {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 17.8rem;
        width: 100%;
      }

      &__title {
        font-size: 6.2rem;
        font-weight: 400;
        line-height: 6.8rem;
        font-family: $fontBodoni;
      }

      &__group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 39rem;
        width: 90%;
        margin-top: 7rem;
        margin-left: 10%;

        &_item {
          width: 25%;
          font-size: 2.1rem;
          line-height: 3.5rem;
        }
      }
    }
  }

  .button--oval {
    margin-top: 5rem;
    color: $black;
    background-color: $white;

    &:hover {
      background-color: rgba($color: $light2Grey, $alpha: 1);
    }
  }

  &__pop-up {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: $black;
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    transition: all 0.5s;
    z-index: 999;

    &.visible {
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
      transition: all 0.5s;
    }

    .button--oval {
      font-size: 1.7rem;
      color: $white;
      background-color: $black;
      margin-top: 0rem;

      &:hover {
        background-color: $black;
      }
    }

    &_header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 10rem;
      width: 100%;
    }

    .pdf__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3rem;
      width: 100%;

      .pdf {
        width: 55%;
      }

      .controls-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: max-content;
        width: 70%;
        font-size: 2.4rem;
        margin-top: 2.2rem;
        border: 1px solid $white;
      }
    }
  }
}

@media only screen and (max-width: $lg) {
  .grotta-home-book {
    &__header {
      &_title {
        width: 50%;
      }
    }

    &__body {
      .explore {
        &__group {
          &_title-box {
            width: 30%;
          }

          &__main-image {
            height: 100%;
            width: 45%;
          }
        }
      }

      .creators {
        &__group {
          height: 45rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .grotta-home-book {
    &__header {
      &_title {
        width: 85%;
      }
    }

    &__body {
      .main-image {
        width: 85%;
      }

      .reviews {
        &__container {
          margin-top: 10.8rem;
        }

        &__description {
          width: 90%;
        }

        &__author {
          font-size: 1.8rem;
        }
      }

      .explore {
        &__container {
          margin-top: 14.6rem;
        }

        &__group {
          flex-direction: column-reverse;

          &_title-box {
            margin-left: 0rem;
            margin-top: 6rem;
            width: 85%;
          }

          &__main-image {
            width: 85%;
          }
        }

        &__description {
          margin-top: 10.3rem;
          width: 90%;
        }

        &__properties {
          width: 90%;
        }
      }

      .creators {
        &__group {
          height: 95rem;
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: $xxxs) {
  .grotta-home-book {
    padding: 28rem 2.8rem 16rem;

    &__header {
      &_title {
        width: 100%;
        font-size: 3.9rem;
        line-height: 5rem;
      }

      &_author {
        margin-top: 15.4rem;
      }
    }

    &__body {
      .main-image {
        width: 100%;
        margin-top: 12rem;
      }

      .reviews {
        &__container {
          margin-top: 19.1rem;
        }

        &__title {
          font-size: 3.7rem;
          line-height: 4.7rem;
        }

        &__description {
          width: 100%;
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 3.5rem;
        }

        &__author {
          margin-top: 6.8rem;
          font-size: 1.6rem;
        }

        .button--oval {
          margin-top: 5.8rem;
        }
      }

      .explore {
        &__container {
          margin-top: 22.6rem;
        }

        &__group {
          &_title-box {
            align-items: center;
            width: 100%;
            margin-top: 3.5rem;

            .title-box__title {
              font-size: 3rem;
              line-height: 4rem;
            }
          }

          &__main-image {
            width: 90%;
          }
        }

        &__description {
          font-size: 1.8rem;
          line-height: 2.8rem;
          margin-top: 19.1rem;
          width: 100%;
        }

        &__properties {
          flex-wrap: wrap;
          justify-content: space-around;
          font-size: 1.6rem;
          line-height: 4.4rem;
          margin-top: 7.5rem;
          width: 100%;

          span {
            margin-bottom: 1.5rem;
          }
        }
      }

      .creators {
        &__container {
          margin-top: 11.8rem;
        }

        &__title {
          font-size: 3rem;
          line-height: 4rem;
        }

        &__group {
          align-items: center;
          height: max-content;
          width: 100%;
          margin-top: 4.6rem;
          margin-left: 0%;

          &_item {
            font-size: 1.8rem;
            line-height: 3.4rem;
            width: max-content;
          }
        }
      }
    }

    .button--oval {
      width: 192px;
      margin-top: 4.6rem;
    }
  }
}
</style>
