import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        user: {
            first_name: 'Emma',
            last_name: 'Halstian',
            initials: 'E',
            name_abbreviation: "Emma H.",
            role: 'developer'
        }
    },
    getters: { getUserInfo(state) { return state.user } },
    mutations: {},
    actions: {}
}