<template>
  <div class="about">
    <div class="about__header">
      <h1 class="about__header_title">About The Grottas</h1>
    </div>

    <div class="about__body">
      <span class="about__body_description intro"
        >A celebrated interior designer and art curator, Sandy Grotta met her husband, Lou, at the
        University of Michigan in 1953. After enrolling in multiple art history courses together,
        the Grotta’s quickly discovered their mutual admiration for contemporary architecture.
        Inspired by the artwork of such renowned craftsmen as Joyce and Edgar Anderson, Toshiko
        Takaezu, Jack Lenor Larsen and, of course, Richard Meier, Sandy completely renovated the
        Grotta’s first home by replacing family antiques with a new assortment of contemporary art
        pieces and furniture.
      </span>

      <img
        class="about__body_photo"
        src="@/assets/images/about/sandy-grotta.png"
        alt="Sandy Grotta"
      />
      <span class="about__body_photo-description">Sandy Grotta</span>

      <span class="about__body_quote"
        >“During the construction of The Grotta House, Sandy served as the chief curator and
        acquired new acquisitions to enhance the Grotta’s existing collection of international art”.
      </span>

      <img class="about__body_photo" src="@/assets/images/about/lou-grotta.png" alt="Lou Grotta" />
      <span class="about__body_photo-description">Lou Grotta</span>

      <span class="about__body_description"
        >Lou’s interest in modern architecture and Scandinavian art stems back to his early years as
        a student at the University of Michigan. In the early eighties, Lou reunited with his
        childhood companion Richard Meier, and despite their differences in opinion concerning craft
        materials, they decided to collaborate on the creation of The Grotta House. And over a span
        of five years, they worked together to design and build a house that incorporated the
        Grotta’s unique appreciation for contemporary art and Meier’s formal elements of design.
        Today, Lou and Sandy continue to practice their curatrial expertise as they hunt down
        dimensional art, textile art, and sculptures that create transparency and reflect the
        natural elements of each contemporary art form.
      </span>

      <img
        class="about__body_photo family"
        src="@/assets/images/about/grotta-family.png"
        alt="Grotta family"
      />

      <span class="about__body_description"
        >I have become increasingly interested in how work can be seen continually and by bigger
        audiences. Museums have large holdings, but they have limited exhibition space. When you
        start to look at exhibition space for craft there’s even less real estate. Technology is one
        way to get images and videos and online exhibitions in front of adences and academic that
        may never get to see the work in a museum. That’s why we created The Grotta House website
        and why I amsupporting websites to keep the work o Toshiko Takaezu and Joyce and Edgar
        Anderson in the forefront. It’s a legacy project for them.</span
      >
    </div>

    <div class="navigation__container">
      <span class="navigation__container_title">Next Chapter: Friends of the Grottas</span>
      <div class="navigation__container_buttons-box">
        <BaseButton
          class="button--white button--oval"
          text="View Friends"
          @click.native="goToPage()"
        />

        <BaseButton
          class="button--outline-white button--oval"
          text="Back to Home"
          @click.native="goToPage('Home')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { BaseButton },

  data() {
    return {};
  },

  computed: {},

  methods: {
    goToPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $black;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;

  &__header {
    margin-top: 15.6rem;

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 28.5rem 13rem;

    &_description {
      display: inline-block;
      text-align: center;
      margin-top: 8.3rem;

      &.intro {
        margin-top: 0rem;
      }
    }

    &_photo {
      //width: 100%;
      margin-top: 15rem;

      &-description {
        font-family: $fontBodoni;
      }
    }

    &_quote {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 2.7rem;
      margin: 18.3rem 0rem 3.3rem;
    }
  }

  .navigation__container {
    display: flex;
    flex-direction: column;
    margin: 7.5rem 0rem 10.4rem;
    padding-left: 30%;
    width: 100%;

    &_title {
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
    }

    &_buttons-box {
      display: flex;
      align-items: center;

      &::after {
        content: "";
        background-color: $white;
        height: 1px;
        flex-grow: 100;
      }

      .button--oval {
        margin-left: 12px;
      }
    }
  }
}

@media only screen and (max-width: $lg) {
  .about {
    &__body {
      padding: 4rem 18.5rem 13rem;
    }
  }
}

@media only screen and (max-width: $sm) {
  .about {
    &__body {
      padding: 4rem 10.5rem 13rem;
    }
  }
}

@media only screen and (max-width: $xxxs) {
  .about {
    font-size: 1.8rem;
    line-height: 2.8rem;

    &__header {
      margin-top: 28.3rem;
      padding: 0rem 2.8rem;

      &_title {
        font-size: 4rem;
        line-height: 6rem;
      }
    }

    &__body {
      padding: 4.7rem 2.8rem 15rem;

      &_photo {
        margin-top: 12rem;
      }

      &_quote {
        font-size: 2.3rem;
        margin: 10.5rem 0rem 3.3rem;
      }
    }

    .navigation__container {
      margin: 0rem 0rem 18.9rem;
      padding-left: 2.6rem;

      &_title {
        font-size: 2.5rem;
        line-height: 3.1rem;
        margin: 0rem 0rem 3rem 0rem;
      }

      &_buttons-box {
        .button--oval {
          margin-left: 0px;
          padding: 12px 18px;
        }

        .button--outline-white {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
