<template>
  <div class="login">
    <div class="login__content">
      <div class="logo"><img :src="require(`@/assets/images/logoBlue.png`)" alt="logo" /></div>
      <ValidationObserver v-slot="{ invalid }" tag="section" class="welcome form">
        <h1>Welcome back</h1>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Enter your email"
              :class="{ 'form-control--invalid': error || errors[0] }"
              :validateError="errors[0]"
              v-model="user.username"
              :element="user.username"
              @input="user.username = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 8 }"
            v-slot="{ errors }"
          >
            <PasswordInput
              placeholder="Enter your password"
              type="password"
              name="password"
              :class="{ 'form-control--invalid': error || errors[0] }"
              :validateError="error || errors[0]"
              v-model="user.password"
              :element="user.password"
              @input="user.password = $event"
            ></PasswordInput>
          </ValidationProvider>
        </div>
        <!--<div class="form__row">
          <div class="form__row_col">
            <BaseCheckbox label="Remember me"></BaseCheckbox>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--justify-end">
            <BaseButton
              text="Forgot Password"
              class="button--only-text"
              @click="login"
            ></BaseButton>
          </div>
        </div>-->
        <div class="form__row">
          <BaseButton
            text="Login"
            class="button--black button--uppercase"
            :disabled="invalid"
            :loading="loader"
            @click="login"
          ></BaseButton>
        </div>
      </ValidationObserver>
      <div class="footer">
        <p>© Collekt 2021</p>
        <ul>
          <li>Terms & Conditions</li>
          <li>Privacy Policy</li>
        </ul>
      </div>
    </div>
    <div class="login__image"></div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseInput,
    PasswordInput,
    BaseButton,
    BaseCheckbox,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      error: "",
    };
  },
  computed: {
    ...mapGetters("auth", { loader: "getLoading" }),
  },
  methods: {
    ...mapActions("auth", ["loginUser"]),
    async login() {
      let result = await this.loginUser(this.user);
      this.error = result.error;
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  height: 100vh;
  padding: 15px 15px;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42%;
    height: 100%;
    margin-right: 5rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 100%;
      margin-right: 0;
    }
    .logo {
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 100px;
        margin: 0 auto;
      }
    }
    .welcome {
      width: 100%;
      padding: 4.5rem 11rem;
      @media only screen and (max-width: $lg) {
        padding: 20px 5rem;
      }
      @media only screen and (max-width: $md) {
        padding: 20px 0;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        max-width: 50rem;
        margin: 0 auto;
        padding: 10px 0 20px;
      }
      h1 {
        font-size: 4.4rem;
        font-weight: 500;
        line-height: 1.34;
        margin-bottom: 4.8rem;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 2.5rem;
          margin-bottom: 3rem;
          text-align: center;
        }
      }
      .button {
        margin-top: 1.3rem;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 10px 25px;
      @media only screen and (max-width: $md) {
        padding: 10px 0;
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        align-items: center;
      }
      p {
        @include type();
        @media only screen and (max-width: $md) {
          margin: 15px auto 0 0;
        }
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
          margin: 15px auto 0;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: $md) {
          justify-content: flex-end;
        }
        li {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.16;
          padding: 0 15px;
          color: $lighBlack;
          cursor: pointer;
          @media only screen and (max-width: $md) {
            padding: 0 15px 0 0;
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            padding: 0 15px;
          }
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
  &__image {
    width: 58%;
    max-width: 105rem;
    margin: 0 0 0 auto;
    height: 100%;
    border-radius: 14px;
    background-image: url("~@/assets/images/login.png");
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: $md) {
      max-height: 500px;
      margin: auto;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      display: none;
    }
  }
}
</style>