import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        artworks: [
            {
                title: "Grandfather Clock",
                artist: 'Donna & Edgar Anderson',
                category: { main: 'furniture', additional: [] },
                tags: { main: 'wood', additional: ['clothes', 'baskets'] },
                status: "Active",
                date_added: 'Aug 18 2021',
            },
            {
                title: "Grandfather Clock",
                artist: 'Donna & Edgar Anderson',
                category: { main: 'furniture', additional: [] },
                tags: { main: 'wood', additional: ['clothes', 'baskets', 'furniture', 'ceramics'] },
                status: "Active",
                date_added: 'Aug 18 2021',
            },
        ],
        artworksHeaders: [
            {
                key: "title",
                title: "Title",
                sortable: true,
            },
            {
                key: "artist",
                title: "Artist",
                sortable: true,
                className: 'lg',
            },
            {
                key: "category",
                title: "Category",
                sortable: true,
                variables: 'tag'
            },
            {
                key: "tags",
                title: "Tags",
                sortable: true,
                className: 'md',
                variables: 'tag'
            },
            {
                key: "status",
                title: "Status",
                sortable: true,
                className: 'md',
                variables: 'status'
            },
            {
                key: "date_added",
                title: "Date Added",
                sortable: true,
                className: 'md',
            },
            {
                key: "edit",
                title: "Edit",
                sortable: false,
                className: 'xsm',
                variables: 'icon',
                icon: 'IconEdit'
            },
        ],
        filters: [
            {
                key: "category",
                title: "Category",
                filters: [
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                ],
                open: false,
            },
            {
                key: "tags",
                title: "Tags",
                filters: [
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                ],
                open: false,
            },
        ],
        selectedFilters: []
    },
    getters: {
        getArtworksHeaders(state) { return state.artworksHeaders },
        getArtworks(state) { return state.artworks },
        getFilters(state) { return state.filters },
        getSelectedFilters(state) { return state.selectedFilters }
    },
    mutations: {
        initSelectedFilters(state, payload) {
            state.selectedFilters = payload
        },
        setFilters(state, payload) {
            let { key, filter } = payload
            for (let el of state.filters) {
                if (el.key === key) {
                    el.filters.forEach(element => {
                        if (element.key === filter) {
                            element.selected = !element.selected
                        }
                    });
                    break;
                }
            }
        },
        setSelectedFilters(state, payload) {
            let { key, filter } = payload
            for (let el of state.selectedFilters) {
                if (el.key === key) {
                    if (el.items.length && el.items.includes(filter)) {
                        el.items = el.items.filter(i => i != filter);
                    } else el.items.push(filter);
                    break;
                }
            }
        }
    },
    actions: {}
}