<template>
  <div class="art">
    <header class="art__header">
      <h3 class="art__header_title">Add Art</h3>
      <div class="art__header_actions">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="clear"
          @click="removeArt"
        ></BaseButton>
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="clear"
          @click="saveArt"
        ></BaseButton>
      </div>
    </header>
    <AutocompleteInput :loading="true" placeholder="Artist"></AutocompleteInput>
    <section class="form">
      <h3 class="form__header">Required Information</h3>
      <div class="form__row">
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Title"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side">
          <BaseSelect
            placeholder="Artist"
            :items="[
              { key: 'key', title: 'First El' },
              { key: 'key2', title: 'Second El' },
            ]"
          ></BaseSelect>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Path"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Status"></BaseInput>
        </div>
      </div>
      <div class="form__row">
        <MultipleAutocompleteInput :loading="true" placeholder="Tag"></MultipleAutocompleteInput>
      </div>
      <div class="form__row form__row--bordered">
        <UploadImage></UploadImage>
      </div>
    </section>
    <section class="form">
      <h3 class="form__header">Details</h3>
      <div class="form__row">
        <BaseTextarea placeholder="Description"></BaseTextarea>
      </div>
      <div class="form__row">
        <div class="form__row_col">
          <BaseInput placeholder="Provenance"></BaseInput>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Date"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Location"></BaseInput>
        </div>
      </div>
    </section>
    <section class="form">
      <h3 class="form__header">Attributes</h3>
      <div class="form__row">
        <div class="form__row_col form__row_col--side form__row_col--side-three">
          <BaseInput placeholder="Height"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side form__row_col--side-three">
          <BaseInput placeholder="Width"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side form__row_col--side-three">
          <BaseInput placeholder="Depth"></BaseInput>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Weight"></BaseInput>
        </div>
        <div class="form__row_col form__row_col--side">
          <BaseInput placeholder="Condition"></BaseInput>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col">
          <BaseInput placeholder="Copyright"></BaseInput>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import UploadImage from "@/components/UploadImage.vue";
import AutocompleteInput from "@/components/inputs/AutocompleteInput.vue";

import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    UploadImage,
    AutocompleteInput,
    MultipleAutocompleteInput,
  },
  methods: {
    removeArt() {},
    saveArt() {},
  },
};
</script>
<style lang="scss" scoped>
.art {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    &_title {
      font-size: 3.35rem;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .button {
        margin-left: 10px;
      }
    }
  }
}
</style>