<template>
  <div class="autocomplete-input" @mouseleave="open = false">
    <div class="autocomplete-input__selected">
      <Tag
        class="tag--capitalize"
        :class="{ 'tag--selected': i.selected }"
        v-for="i in selectedItems"
        :key="i"
        :value="i"
        :close="true"
        @deleteTag="selectFilter({ val: i })"
      ></Tag>
    </div>
    <div
      class="autocomplete-input__input"
      :class="{ 'autocomplete-input__input--loading': loading }"
    >
      <input
        required
        type="text"
        v-model="item"
        @input="
          $emit('searchMatches', item);
          open = true;
        "
        @keyup.enter.prevent="
          $emit('addItem', item);
          selectFilter({ val: item });
        "
        :disabled="disabled"
      />
      <label class="autocomplete-input__label">{{ placeholder }}</label>
      <IconLoader
        class="autocomplete-input__icon autocomplete-input__icon--loading"
        v-if="loading"
      ></IconLoader>
      <span class="autocomplete-input__icon" @click="$emit('addItem', item)">
        Add {{ placeholder }}
        <IconButton class="button--xsm">
          <IconPlus width="16" height="16"></IconPlus>
        </IconButton>
      </span>
    </div>

    <div class="autocomplete-input__match" v-if="matches.length && open">
      <p class="autocomplete-input__match_title">Search {{ placeholder }}</p>
      <template v-if="matches.length">
        <ul>
          <li v-for="match in matches" :key="match.id">
            <button
              @click.prevent="selectFilter({ val: match.title })"
              class="autocomplete-input__match_item"
            >
              {{ match.title }}
            </button>
          </li>
        </ul>
      </template>
      <h6 v-else>No Results Found</h6>
    </div>
  </div>
</template>
<script>
import IconLoader from "@/components/icons/IconLoader.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import Tag from "@/components/Tag.vue";
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
    matches: {
      type: Array,
      default() {
        return [
          { id: 1, title: "Title" },
          { id: 2, title: "Test 2" },
        ];
      },
    },
    disabled: { type: Boolean, default: false },
  },
  components: { IconLoader, IconButton, IconPlus, Tag },
  data() {
    return {
      item: "",
      selectedItems: [],
      open: false,
    };
  },
  created() {
    this.selectedItems = this.items;
  },
  watch: {
    items(val) {
      this.selectedItems = val;
    },
    open(val) {
      if (!val) {
        this.item = "";
        this.$emit("selectMatch", "");
      }
    },
  },
  methods: {
    selectFilter({ val }) {
      console.log(val);
      let selectedItems = this.selectedItems;
      if (selectedItems.length && selectedItems.includes(val)) {
        selectedItems = selectedItems.filter(i => i != val);
      } else selectedItems.push(val);

      this.selectedItems = selectedItems;
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
  position: relative;
  width: 100%;
  padding-bottom: 11px;
  &__input {
    position: relative;
    margin-top: 10px;
    input {
      height: 9rem;
      padding: 0 15.5rem 0 2.7rem;
      border: 0.5px solid $lighBlack;
      border-radius: 12px;
      @include type();
      color: $lighBlack;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &--loading {
      input {
        padding-right: 20rem;
      }
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    @include type($fw: 500);
    cursor: pointer;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &--loading {
      right: 16.5rem;
      cursor: default;
    }
    .button {
      margin-left: 10px;
    }
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: rgba($color: $lighBlack, $alpha: 0.7);
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  input:focus ~ &__label,
  input:valid ~ &__label {
    top: 0;
  }
  &__selected {
    display: flex;
    flex-wrap: wrap;
  }
  &__match {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
    padding: 24px 22px 16px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #efefef;
    z-index: 2;
    &_title {
      @include type($fw: 700);
      text-transform: capitalize;
      margin-bottom: 25px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &_item {
      @include type();
      color: $white;
      text-align: center;
      border-radius: 8px;
      background-color: $greyDark;
      padding: 6px 2rem;
      margin-bottom: 8px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $blueLight;
        color: $blue;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
}
</style>