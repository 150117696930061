<template>
  <div class="page">
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock :items="filters" @selectFilter="selectFilter"></FilterBlock>
        <IconButton class="button--auto button--uppercase" text="add art" @click="modal = true">
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters"
        ><Tag
          class="tag--capitalize"
          :class="{ 'tag--selected': i.selected }"
          v-for="i in filter.items"
          :key="i"
          :value="i"
          :close="true"
          @deleteTag="selectFilter({ key: filter.key, filter: i })"
        ></Tag
      ></template>
    </div>
    <Table :items="items" :headerItems="headerItems"></Table>
    <PopUp :show="modal" @hideModal="modal = false">
      <Art></Art>
    </PopUp>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import { mapMutations, mapGetters } from "vuex";
import PopUp from "@/components/PopUp.vue";
import Art from "./components/Art.vue";
export default {
  components: { HorisontalTabsList, FilterBlock, IconButton, IconPlusBold, Table, Tag, PopUp, Art },
  data() {
    return {
      tabs: [
        {
          title: "Artwork",
          path: "/admin/collection/artwork",
          key: "artwork",
        },
        {
          title: "Artists",
          path: "/admin/collection/artists",
          key: "artists",
        },
      ],
      modal: false,
    };
  },
  created() {
    let selectedFilters = [];
    this.filters.forEach(item => {
      selectedFilters.push({ key: item.key, items: [] });
      this.initSelectedFilters(selectedFilters);
    });
  },
  computed: {
    ...mapGetters("artworks", {
      items: "getArtworks",
      headerItems: "getArtworksHeaders",
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
  },
  methods: {
    ...mapMutations("artworks", ["initSelectedFilters", "setSelectedFilters", "setFilters"]),
    selectFilter(val) {
      this.setFilters(val);
      this.setSelectedFilters(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        margin: 0 0 0 2rem;
      }
    }
  }
  &__filters {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 7px;
    .tag {
      margin-left: 1rem;
    }
  }
}
</style>