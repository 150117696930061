<template>
  <ValidationObserver v-slot="{ invalid }" tag="div" class="art">
    <header class="art__header">
      <h3 class="art__header_title">Add Art</h3>
      <div class="art__header_actions">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="Delete Artist"
          @click="removeArtist"
        ></BaseButton>
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="Save Changes"
          :disabled="invalid"
          @click="saveArtist"
        ></BaseButton>
      </div>
    </header>
    <section class="form">
      <h3 class="form__header">Basic Information</h3>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: true }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Fist Name"
            :validateError="errors[0]"
            :element="artist.first_name"
            @input="artist.first_name = $event"
          ></BaseInput>
        </ValidationProvider>
        <div class="form__row_col form__row_col--side">
          <BaseInput
            placeholder="Last Name"
            :element="artist.last_name"
            @input="artist.last_name = $event"
          ></BaseInput>
        </div>
      </div>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: false }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Path"
            :validateError="errors[0]"
            :element="artist.slug"
            @input="artist.slug = $event"
          ></BaseInput>
          <span class="simple-text">Example: {{ `${url}/artists/${artist.slug || "path"}` }}</span>
        </ValidationProvider>
        <div class="form__row_col form__row_col--side">
          <BaseSelect
            placeholder="Status"
            :selected="artist.status"
            :items="[
              { key: '0', title: 'Active' },
              { key: '1', title: 'Inactive' },
            ]"
            @action="artist.status = $event.key"
          ></BaseSelect>
        </div>
      </div>
      <div class="form__row">
        <MultipleAutocompleteInput :loading="false" placeholder="Tag"></MultipleAutocompleteInput>
      </div>
      <div class="form__row form__row--bordered">
        <UploadImage></UploadImage>
      </div>
    </section>
    <section class="form">
      <h3 class="form__header">Biography</h3>
      <div class="form__row">EDITOR</div>
    </section>
    <section class="form">
      <h3 class="form__header">Connections</h3>
      <div class="form__row">
        <div class="form__row_col">
          <AutocompleteInput :loading="false" placeholder="Artist"></AutocompleteInput>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col">
          <BaseSelect
            placeholder="Status"
            :items="[
              { key: 'book1', title: 'Book 1' },
              { key: 'book2', title: 'Book 2' },
            ]"
          ></BaseSelect>
        </div>
      </div>
      <div class="form__row">
        <div class="form__row_col">
          <AutocompleteInput :loading="false" placeholder="Artist"></AutocompleteInput>
        </div>
      </div>
    </section>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import UploadImage from "@/components/UploadImage.vue";
import AutocompleteInput from "@/components/inputs/AutocompleteInput.vue";

import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    UploadImage,
    AutocompleteInput,
    MultipleAutocompleteInput,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      artist: {
        first_name: "",
        last_name: "",
        slug: "",
        status: 0,
        biography: "",
      },
    };
  },
  methods: {
    ...mapActions("artists", ["apiPostArtist"]),
    removeArtist() {},
    async saveArtist() {
      await this.apiPostArtist(this.artist);
    },
  },
};
</script>
<style lang="scss" scoped>
.art {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    &_title {
      font-size: 3.35rem;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .button {
        margin-left: 10px;
      }
    }
  }
}
</style>