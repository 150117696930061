<template>
  <div class="year-line__wrapper" :class="{ right: position === 'right' }">
    <div class="year-line__container">
      <span>{{ year }}</span>
      <div class="year-line__point"></div>
      <div class="year-line__vertical-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    year: { type: String },
    position: {
      type: String,
      validator: function (value) {
        return ["left", "right"].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.year-line__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .year-line__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 13rem;

    .year-line__point {
      height: 0.9rem;
      width: 0.9rem;
      border-radius: 50%;
      background-color: $red;
    }

    .year-line__vertical-line {
      height: 3.2rem;
      width: 0.3rem;
      background-color: $red;
    }
  }
}

@media only screen and (max-width: $xxxs) {
  .year-line__wrapper {
    .year-line__container {
      .year-line__point {
        height: 0.7rem;
        width: 0.7rem;
      }

      .year-line__vertical-line {
        height: 1.5rem;
        width: 0.2rem;
      }
    }
  }
}
</style>
