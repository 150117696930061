import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        artists: [],
        artistsHeaders: [
            {
                key: "first_name",
                title: "Artist Name",
                sortable: true,
                className: 'capitalize',
            },
            {
                key: "tags",
                title: "Tags",
                sortable: true,
                className: 'md',
                variables: 'tag'
            },
            {
                key: "status",
                title: "Status",
                sortable: true,
                className: 'md',
                variables: 'status'
            },
            {
                key: "edit",
                title: "Edit",
                sortable: false,
                className: 'xsm',
                variables: 'icon',
                icon: 'IconEdit'
            },
        ],
        filters: [
            {
                key: "category",
                title: "Category",
                filters: [
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                ],
                open: false,
            },
            {
                key: "tags",
                title: "Tags",
                filters: [
                    { title: "Cotton", key: "cotton", selected: false },
                    { title: "Ceramic", key: "ceramic", selected: false },
                    { title: "Fiber", key: "fiber", selected: false },
                    { title: "Gold", key: "gold", selected: false },
                ],
                open: false,
            },
        ],
        selectedFilters: []
    },
    getters: {
        getArtistsHeaders(state) { return state.artistsHeaders },
        getArtists(state) { return state.artists },
        getFilters(state) { return state.filters },
        getSelectedFilters(state) { return state.selectedFilters }
    },
    mutations: {
        initSelectedFilters(state, payload) {
            state.selectedFilters = payload
        },
        setFilters(state, payload) {
            let { key, filter } = payload
            for (let el of state.filters) {
                if (el.key === key) {
                    el.filters.forEach(element => {
                        if (element.key === filter) {
                            element.selected = !element.selected
                        }
                    });
                    break;
                }
            }
        },
        setSelectedFilters(state, payload) {
            let { key, filter } = payload
            for (let el of state.selectedFilters) {
                if (el.key === key) {
                    if (el.items.length && el.items.includes(filter)) {
                        el.items = el.items.filter(i => i != filter);
                    } else el.items.push(filter);
                    break;
                }
            }
        },
        setArtists(state, payload) {
            state.artists = payload.map(el => {
                let tags = {
                    main: '',
                    additional: []
                }
                if (el.tags.length > 0) {
                    tags.main = el.tags[0]
                    tags.additional = el.tags.slice(1)
                }
                return {
                    ...el,
                    first_name: `${el.last_name}, ${el.first_name}`,
                    tags: tags,
                }
            })

        }
    },
    actions: {
        async apiGetArtists({ commit }, { status = 1, tags = '', sort = 'first_name', order = 'asc', page = '1' }) {
            let query = {
                status,
                tags,
                sort,
                order,
                page
            }
            let queryStr = '';
            for (let key in query) {
                if (query[key] != '') {
                    queryStr += `${key}=${query[key]}&`;
                }
            }
            await Vue.axios.get(`/api/admin/artists?${queryStr}`)
                .then((res) => res.data)
                .then((data) => {
                    commit('setArtists', data.response)
                })
        },
        async apiPostArtist({ commit }, payload) {
            await Vue.axios.post("/api/admin/artist", payload)
                .then((res) => res.data)
                .then((data) => {
                    console.log(data)
                })
        }
    }
}