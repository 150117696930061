import Vue from 'vue'
import router from '@/router'
export default {
    namespaced: true,
    state: {
        loading: false,
        access: {
            access_token: null,
            expires_in: null
        }
    },
    getters: {
        isAuthenticated(state) { return state.access },
        getLoading(state) { return state.loading }
    },
    mutations: {
        setToken(state, { access_token, expires_in }) {
            Vue.$cookies.set("token", access_token, expires_in);
            state.token = { access_token, expires_in };
        },
        changeLoader(state, payload) { state.loading = payload }
    },
    actions: {
        async loginUser({ commit }, payload) {
            commit('changeLoader', true)
            let result = {
                success: false,
                error: null
            }
            await Vue.axios.post("/api/login", payload)
                .then((res) => res.data)
                .then((data) => {
                    result.success = true
                    commit('setToken', data)
                    router.push("/admin");
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        result.error = e.response.data.detail.message
                    }
                })
                .finally(() => {
                    commit('changeLoader', false)
                })
            return result
        },
        async logoutUser({ commit }) {
            await Vue.axios.post("/api/logout")
                .then((res) => res.data)
                .then((data) => {
                    commit('setToken', {
                        access_token: null,
                        expires_in: null
                    })
                    router.push("/login");
                })
        }
    }
}