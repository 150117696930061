<template>
  <div class="uploader">
    <div class="uploader__image" :style="{ backgroundImage: image ? `url(${image})` : '' }">
      <div class="uploader__input">
        <input
          class="uploader__input_input"
          type="file"
          :name="`image_${id}`"
          :id="id"
          @change="onChange"
          ref="file"
          :accept="accept"
          :disabled="disabled"
        />
        <label v-show="!image" class="uploader__input_label" :for="id">
          <p>Upload</p>
          <IconButton class="button--sm">
            <IconPlus width="20" height="20"></IconPlus>
          </IconButton>
          <p>
            <span>{{ acceptString }} </span>up to {{ size }} MB
          </p> </label
        ><label v-show="image" :for="id" class="uploader__edit">
          <IconButton class="button--sm"><IconEdit></IconEdit> </IconButton>
        </label>
        <template v-if="errors != null">
          <p class="uploader__input_error" v-for="(error, id) in errors" :key="id">
            <span v-for="(i, id) in error" :key="id">{{ i }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
export default {
  props: {
    id: { type: String, default: "upload" },
    accept: {
      type: Array,
      default() {
        return ["png", "jpeg", "jpg"];
      },
    },
    size: { type: [Number, String], default: "2" },
    image: { type: String, require: true },
    disabled: { type: Boolean, default: false },
  },
  components: {
    IconButton,
    IconEdit,
    IconPlus,
  },
  data() {
    return {
      types: "",
      acceptString: "",
      fileList: null,
      errors: null,
    };
  },
  created() {
    let types = this.accept.map(el => {
      return `image/${el}`;
    });
    this.types = types.join(",");
    this.acceptString = this.accept.join(", ");
  },
  methods: {
    async onChange(event) {
      const size = parseFloat(event.target.files[0].size / (1024 * 1024)).toFixed(2);
      if (size > this.size) {
        this.$toasted.show(`Please select image size less than ${this.size} MB`, {
          duration: 200000,
          type: "error",
        });
      } else {
        this.fileList = this.$refs.file.files[0].name;
        this.$emit("upload", event);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploader {
  width: 29rem;
  height: 19.8rem;
  border-radius: 8px;
  border: 1px solid $grey;
  &__image {
    height: 100%;
  }
  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &_input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
    }
    &_label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      p {
        margin: 13px 0;
        @include type($fw: 500);
        color: $lighBlack;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
        span {
          font-weight: 500;
          text-transform: uppercase;
          color: $lighBlack;
        }
      }
    }
  }
}
</style>