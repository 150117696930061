<template>
  <div class="collection">
    <h1>Collection</h1>
    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 4rem;
}
</style>