<template>
  <div
    class="additional-tags"
    @mouseenter="$emit('getParentClientRect')"
    @click="!visible ? setRect() : false"
    @mouseleave="hidePopUp"
  >
    <span class="additional-tags__count">+{{ items.length }}</span>
    <div
      ref="additionalPopUp"
      class="additional-tags__body"
      :class="{ 'additional-tags__body--visible': visible }"
    >
      <Tag v-for="tag in items" :key="tag" :value="tag"></Tag>
    </div>
  </div>
</template>
<script>
import Tag from "@/components/Tag.vue";
export default {
  components: { Tag },
  props: {
    items: { type: Array },
    parentClientRect: { type: [DOMRect, Object] },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    setRect() {
      let parentClientRect = this.parentClientRect;
      let el = this.$refs.additionalPopUp,
        elClientRect = el.getBoundingClientRect();

      let rightDiff = parentClientRect.right - elClientRect.right;
      let topDiff = elClientRect.top - elClientRect.height - 65 - parentClientRect.top;

      if (rightDiff > 0) {
        el.style.right = 0;
      } else {
        el.style.right = `${elClientRect.right - parentClientRect.right + 15}px`;
      }

      if (topDiff < 0) {
        el.style.top = "20px";
      } else {
        el.style.top = "-40px";
      }
      this.visible = this.items ? true : false;
    },
    hidePopUp() {
      this.visible = false;
      this.$refs.additionalPopUp.style.top = "0";
      this.$refs.additionalPopUp.style.right = "0";
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-tags {
  position: relative;
  cursor: pointer;
  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid $black;
    color: $black;
    background-color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.15;
    cursor: pointer;
  }
  &:hover {
    .additional-tags__count {
      background-color: $black;
      color: $white;
    }
  }
  &__body {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    display: flex;
    border-radius: 12px;
    padding: 10px 12px;
    background-color: $white;
    box-shadow: 0 2px 16px rgba($color: #000000, $alpha: 0.25);
    z-index: 2;
    &--visible {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
    .tag {
      margin: 0 10px 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>