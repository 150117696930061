<template>
  <button class="tag" @click="$emit('click')">
    <span class="tag__text">{{ value }}</span>
    <span class="tag__close" v-if="close" @click="$emit('deleteTag')">
      <IconClose width="6" height="6" viewBox="0 0 13 13"></IconClose>
    </span>
  </button>
</template>
<script>
import IconClose from "@/components/icons/IconClose.vue";
export default {
  props: { value: String, close: { type: Boolean, default: false } },
  components: { IconClose },
};
</script>
<style lang="scss" scoped>
.tag {
  height: 30px;
  min-width: 6.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: $blueLight;
  margin: 0 5px 12px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &__text {
    @include type();
    color: $blue;
    transition: all 0.3s ease-in-out;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 4px;
    border: 1px solid $blue;
    margin-left: 5px;
    svg {
      width: auto;
      height: auto;
      &::v-deep g {
        fill: $blueDark;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  &--selected {
    background-color: rgba($color: $black, $alpha: 0.5);
    .tag__text {
      color: $white;
    }
    .tag__close {
      border-color: $white;
      svg::v-deep g {
        fill: $white;
      }
    }
  }
  &--capitalize {
    .tag__text {
      text-transform: capitalize;
    }
  }
}
</style>