<template>
  <div
    class="exteriors"
    @mousewheel.prevent="mouseWheel"
    @touchstart="touschSwap"
    @touchend="touschSwap"
  >
    <Loader :loading="loading"></Loader>

    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 1">
        <BaseButton
          class="button--white button--ellipse button--lg"
          text="Go back"
          @click="goBack"
        ></BaseButton>
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_1.png"
          alt="exterior_1"
        />
        <div class="exteriors__page__body__shapes">
          <img class="exteriors__page__body__shape" src="@/assets/images/shape.svg" alt="shape" />
          <img
            class="exteriors__page__body__shape_mini"
            src="@/assets/images/shape.svg"
            alt="shape"
          />
        </div>
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <h2 class="exteriors__page__body__sub-title">Chapter: Exteriors</h2>
            <h1 class="exteriors__page__body__title">Meet the Grottas</h1>
            <p class="exteriors__page__body__description">
              The layout of the house relies on the formal elements of design to produce its complex
              structure. Geometrically placed within the hemicycle of the landscape’s western woods,
              the cylindrical focal point of the house connects to a rectangular block that runs a
              line from north to south and features white enamel panels and concrete walkway that
              lead to a gazebo on its southern edge.
            </p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 2">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_2.png"
          alt="exterior_2"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">
              Today, Lou and Sandy continue to practice their curatorial expertise as they hunt down
              dimensional art, textile art, and sculptures that create transparency and reflect the
              natural elements of each contemporary art form.
            </p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 3">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_3.png"
          alt="exterior_3"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">Back Hallway</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 4">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_4.png"
          alt="exterior_4"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">A passerelle outside to the upper garden</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 5">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_5.png"
          alt="exterior_5"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">Central cylindrical double-height space</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 6">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_6.png"
          alt="exterior_6"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">Entry View</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 7">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_7.png"
          alt="exterior_7"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">Back Side View</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Tom Grotta
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 8">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_7.png"
          alt="exterior_7"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">A passerelle outside to the upper garden</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                TBD
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 9">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_9.png"
          alt="exterior_9"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">If we had Mount Fuji outside...</p>
            <p class="exteriors__page__body__text exteriors__page__body__text_right">
              ...then we’d be the perfect house story
            </p>
            <p class="exteriors__page__body__text exteriors__page__body__text_author">
              -Lou Grotta
            </p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Scott Francis
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              0{{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 10">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_10.png"
          alt="exterior_10"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body">
            <p class="exteriors__page__body__text">Covered Passage</p>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Jake Di Pietro
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              {{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition
      name="exteriors__page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="exteriors__page" v-show="pageCount === 11">
        <img
          class="exteriors__page__image"
          src="@/assets/images/house/exterior_11.png"
          alt="exterior_11"
        />
        <div class="exteriors__page__content">
          <div class="exteriors__page__body exteriors__page__body_finished">
            <h2 class="exteriors__page__body__sub-title">Next Chapter: The History</h2>
            <div class="exteriors__page__body__footer">
              <BaseButton
                class="button--white button--ellipse button--lg"
                text="The History"
                @click="openHistoryPage"
              ></BaseButton>
              <BaseButton
                class="button--outline-white button--ellipse button--lg"
                text="Back to Start"
                @click="toStart"
              ></BaseButton>
              <div class="exteriors__page__body__footer__line"></div>
            </div>
          </div>
          <div class="exteriors__page__footer">
            <p class="exteriors__page__footer__copyright">
              ©
              <span
                class="
                  exteriors__page__footer__copyright exteriors__page__footer__copyright__author
                "
              >
                Jake Di Pietro
              </span>
            </p>
            <p class="exteriors__page__footer__description">scroll to discover</p>
            <p class="exteriors__page__footer__page">
              {{ pageCount }}
              <span class="exteriors__page__footer__page exteriors__page__footer__page__count">
                /11
              </span>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    Loader,
    BaseButton,
  },
  data() {
    return {
      pageCount: 1,
      loading: false,
      scrollDeltaY: 0,
      animationMove: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.id > 11 || to.params.id < 1) next({ path: "/exteriors/1" });
    else
      next(vm => {
        vm.pageCount = +to.params.id;
      });
  },
  created() {},
  methods: {
    beforeEnter(el) {
      this.animationMove = true;
      el.classList.add(`exteriors__page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterEnter(el) {
      el.classList.remove(`exteriors__page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    beforeLeave(el) {
      this.animationMove = true;
      el.classList.add(`exteriors__page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterLeave(el) {
      el.classList.remove(`exteriors__page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    goBack() {
      this.$router.push({ path: "/" });
    },
    openHistoryPage() {
      this.$router.push({ path: "/history" });
    },
    toStart() {
      this.$router.push({ path: "/exteriors/1" });
      this.pageCount = 1;
    },
    mouseWheel(event) {
      if (!this.animationMove) {
        this.scrollDeltaY = event.deltaY;
        if (event.deltaY > 0 && this.pageCount < 11) {
          this.pageCount++;
          this.$router.push({ path: `/exteriors/${this.pageCount}` });
        }
        if (event.deltaY < 0 && this.pageCount > 1) {
          this.pageCount--;
          this.$router.push({ path: `/exteriors/${this.pageCount}` });
        }
      }
    },
    touschSwap(event) {
      if (!this.animationMove) {
        if (event.type === "touchstart") {
          this.scrollDeltaY = event.changedTouches[0].clientY;
        }
        if (event.type === "touchend") {
          const newScrollDeltaY = event.changedTouches[0].clientY;
          const deltaY = this.scrollDeltaY - newScrollDeltaY;
          this.mouseWheel({ deltaY });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exteriors {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  &__page {
    position: absolute;
    height: 100%;
    width: 100%;
    &__fade {
      &-enter-active,
      &-leave-active {
        transition: all 1s ease;
      }
      &-enter {
        &-up {
          animation: pageEnterUp 1s ease-in-out;
        }
        &-down {
          animation: pageLeaveDown 1s ease-in-out;
        }
      }
      &-leave {
        &-up {
          animation: pageLeaveUp 1s ease-in-out;
        }
        &-down {
          animation: pageEnterDown 1s ease-in-out;
        }
      }
    }
    &__image {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: $xxs) {
        width: auto;
        max-width: max-content;
      }
    }
    & > .button {
      position: absolute;
      max-width: 136px;
      z-index: 999;
      font-size: 1.8rem;
      left: 50px;
      top: 70px;
      @media (max-width: $xxs) {
        left: 27px;
        top: 45px;
      }
    }
    &__content {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__body {
      padding-left: 16.4rem;
      margin-bottom: 50px;
      width: max-content;
      @media (max-width: $xxs) {
        padding: 0 25px;
        width: 100%;
      }
      &__shapes {
        position: absolute;
        top: 150px;
        right: 170px;
        @media (max-width: $xxs) {
          top: 155px;
          right: 0;
        }
      }
      &__shape {
        width: 190px;
        height: 190px;
        @media (max-width: $xxs) {
          width: 140px;
          height: 140px;
        }
        &_mini {
          margin-top: 50px;
          width: 110px;
          height: 110px;
          transform: rotate(-270deg);
          @media (max-width: $xxs) {
            margin-top: 35px;
            width: 80px;
            height: 80px;
          }
        }
      }
      &_finished {
        padding-left: 38rem;
        width: 100%;
        @media (max-width: $xxs) {
          padding: 0 0 0 25px;
        }
      }
      &__title {
        font-family: $fontBodoni;
        color: $white;
        font-size: 6.2rem;
        font-weight: 500;
        letter-spacing: -1.03px;
        line-height: 1.01;
        margin-bottom: 22px;
        @media (max-width: $xxs) {
          font-size: 4.8rem;
          line-height: 1.62;
          letter-spacing: -0.82px;
        }
      }
      &__sub-title {
        font-family: $fontBodoni;
        color: $white;
        font-size: 3.1rem;
        font-weight: 400;
        letter-spacing: -0.52px;
        line-height: 1.42;
        margin-bottom: 20px;
        @media (max-width: $xxs) {
          font-size: 2.4rem;
          line-height: 1.83;
          letter-spacing: -0.42px;
        }
      }
      &__text {
        font-family: $fontBodoni;
        color: $white;
        font-size: 3.1rem;
        font-weight: 500;
        letter-spacing: -0.52px;
        line-height: 1.42;
        max-width: 842px;
        @media (max-width: $xxl) {
          max-width: 720px;
        }
        @media (max-width: $xxs) {
          font-size: 2.4rem;
          line-height: 1.43;
          letter-spacing: -0.47px;
          max-width: 380px;
        }
        &_right {
          padding-left: 75px;
          @media (max-width: $xxs) {
            padding-left: 50px;
          }
        }
        &_author {
          font-family: $fontOpenSans;
          font-size: 2.4rem;
          font-weight: 400;
          letter-spacing: -0.38px;
          line-height: 1.3;
          text-align: right;
          @media (max-width: $xxs) {
            font-size: 1.8rem;
            line-height: 1.5;
            letter-spacing: -0.32px;
          }
        }
      }
      &__description {
        font-family: $fontOpenSans;
        color: $white;
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: -0.3px;
        line-height: 1.67;
        max-width: 997px;
        @media (max-width: $xxs) {
          font-size: 1.6rem;
          line-height: 2;
          letter-spacing: -0.27px;
          width: 100%;
          max-width: 380px;
        }
      }
      &__footer {
        display: flex;
        align-items: center;
        width: 100%;
        & > .button {
          min-width: 2rem;
          width: max-content;
          flex-shrink: 0;
          &:first-of-type {
            margin-right: 15px;
          }
          @media (max-width: $xxs) {
            min-width: 15rem;
          }
        }
        &__line {
          height: 1px;
          min-width: 2.9rem;
          width: 100%;
          background-color: $white;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 50px 25px;
      &__copyright {
        position: relative;
        font-family: $fontOpenSans;
        color: $white;
        font-size: 3rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        @media (max-width: $xxs) {
          width: 100%;
        }
        &__author {
          position: absolute;
          width: max-content;
          right: 0;
          transform: translate(100%, 0);
          opacity: 0;
          font-size: 1.6rem;
          letter-spacing: -0.3px;
          line-height: 2.75;
          transition: all 0.5s ease;
          padding-left: 15px;
          @media (max-width: $xxs) {
            padding-left: 10px;
          }
        }
        &:hover .exteriors__page__footer__copyright__author {
          opacity: 1;
        }
      }
      &__description {
        font-family: $fontBodoni;
        color: $white;
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: -0.3px;
        line-height: 2.44;
      }
      &__page {
        font-family: $fontOpenSans;
        color: $white;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 2.2;
        &__count {
          font-size: 1.6rem;
          line-height: 2.75;
          opacity: 0.6;
        }
      }
    }
  }
}
@keyframes pageEnterUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes pageLeaveUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes pageEnterDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes pageLeaveDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>