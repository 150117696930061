<template>
  <div class="home">
    <Loader :loading="loading"></Loader>
    <div class="home__background">
      <div
        class="home__background__element home__background__element--left"
        :class="{
          'home__background__element--visible': exteriorActive,
          'home__background__element--hidden': interiorActive,
        }"
      >
        <img src="@/assets/images/house/exterior.png" alt="exterior" />
        <div
          class="home__background__element__content home__background__element__content--left"
          :class="{ 'home__background__element__content--visible': exteriorActive }"
        >
          <p
            class="
              home__background__element__content__text
              home__background__element__content__text--left
            "
          >
            Nestled in the midst of flourishing trees and asymmetrical slopes, The Grotta House
            provides a rectangular and smooth surface that contrast with its surrounding terrain,
            enhancing its natural beauty.
          </p>
          <div class="home__background__element__footer home__background__element__footer--left">
            <BaseButton
              class="button--white button--ellipse button--lg"
              text="Explore Exteriors"
              @click="openExteriorsToure"
            ></BaseButton>
            <BaseButton
              class="button--outline-white button--ellipse button--lg"
              text="Go Back"
              @click="goBack"
            ></BaseButton>
            <div class="home__background__element__footer__line"></div>
          </div>
        </div>
      </div>
      <div
        class="home__background__element home__background__element--right"
        :class="{
          'home__background__element--visible': interiorActive,
          'home__background__element--hidden': exteriorActive,
        }"
      >
        <img src="@/assets/images/house/interior.png" alt="interior" />
        <div
          class="home__background__element__content home__background__element__content--right"
          :class="{ 'home__background__element__content--visible': interiorActive }"
        >
          <p
            class="
              home__background__element__content__text
              home__background__element__content__text--right
            "
          >
            Welcome to The Grotta House, a living expedition of contemporary art forms from across
            the globe.
          </p>
          <div class="home__background__element__footer home__background__element__footer--right">
            <div class="home__background__element__footer__line"></div>
            <BaseButton
              class="button--outline-white button--ellipse button--lg"
              text="Go Back"
              @click="goBack"
            ></BaseButton>
            <BaseButton
              class="button--white button--ellipse button--lg"
              text="Explore Interiors"
              @click="openInteriorToure"
            ></BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="home__surface" :class="{ 'home__surface--hidden': !surfaceActive }">
      <h1>The Grotta House</h1>
      <p>A MARRIAGE OF ARCHITECTURE AND CRAFT</p>
      <div class="home__surface__footer">
        <div class="home__surface__footer__left">
          <div class="home__surface__footer__line"></div>
          <BaseButton
            class="button--white button--ellipse button--lg"
            text="Exterior"
            @click="openExterior"
          ></BaseButton>
        </div>
        <div class="home__surface__footer__right">
          <BaseButton
            class="button--white button--ellipse button--lg"
            text="Interior"
            @click="openInterior"
          ></BaseButton>
          <div class="home__surface__footer__line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
export default {
  name: "Home",
  components: {
    Loader,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      exteriorActive: false,
      interiorActive: false,
      surfaceActive: true,
    };
  },
  created() {
    /*     this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 3000); */
  },
  methods: {
    openExterior() {
      this.exteriorActive = true;
      this.interiorActive = false;
      this.surfaceActive = false;
    },
    openInterior() {
      this.interiorActive = true;
      this.exteriorActive = false;
      this.surfaceActive = false;
    },
    openInteriorToure() {},
    openExteriorsToure() {
      this.$router.push({ path: "/exteriors/1" });
    },
    goBack() {
      this.surfaceActive = true;
      this.exteriorActive = false;
      this.interiorActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  height: 100vh;
  width: 100vw;
  &__background {
    display: flex;
    height: 100%;
    img {
      position: absolute;
      height: 100%;
      width: auto;
      max-width: max-content;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &__element {
      position: relative;
      width: 50%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      transition: all 1s ease 0s;
      &--left {
        border-right: 2px solid $red;
        padding-left: 16rem;
        @media (max-width: $xxs) {
          padding-left: 2.7rem;
        }
      }
      &--right {
        justify-content: flex-end;
        border-left: 2px solid $red;
        padding-right: 16rem;
        @media (max-width: $xxs) {
          padding-right: 2.7rem;
        }
      }
      &--visible {
        width: 75%;
        @media (max-width: $xxs) {
          width: 90%;
        }
      }
      &--hidden {
        width: 25%;
        @media (max-width: $xxs) {
          width: 10%;
        }
      }
      &__content {
        position: absolute;
        width: 100%;
        padding-bottom: 7rem;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all 0.5s ease 0s;
        &--visible {
          transition: all 1s ease 0s;
          opacity: 1;
        }
        &--left {
          align-items: flex-start;
        }
        &--right {
          align-items: flex-end;
        }
        &__text {
          font-family: $fontBodoni;
          font-size: 3.8rem;
          font-weight: 500;
          color: $white;
          letter-spacing: -0.63px;
          line-height: 1.26;
          margin-bottom: 50px;
          @media (max-width: $xxs) {
            font-size: 2.8rem;
            letter-spacing: -0.47px;
            line-height: 1.42;
          }
          @media (max-width: $xxxs) {
            font-size: 2.4rem;
          }
          &--left {
            text-align: left;
            width: 934px;
            @media (max-width: $xxl) {
              width: 756px;
            }
            @media (max-width: $xxs) {
              width: 344px;
            }
            @media (max-width: $xxxs) {
              width: 278px;
            }
          }
          &--right {
            text-align: right;
            width: 654px;
            @media (max-width: $xxl) {
              width: 448px;
            }
            @media (max-width: $xxs) {
              width: 344px;
            }
            @media (max-width: $xxxs) {
              width: 278px;
            }
          }
        }
      }
      &__footer {
        display: flex;
        align-items: center;
        width: 100%;
        .button {
          min-width: 18rem;
          width: max-content;
          flex-shrink: 0;
          &:first-of-type {
            margin-right: 15px;
          }
          @media (max-width: $xxs) {
            min-width: 12rem;
          }
          @media (max-width: $xxxs) {
            min-width: 10rem;
          }
        }
        &--left {
          justify-content: flex-start;
        }
        &--right {
          justify-content: flex-end;
        }
        &__line {
          height: 1px;
          min-width: 2.9rem;
          width: 100%;
          background-color: $white;
        }
      }
    }
  }
  &__surface {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($color: $black, $alpha: 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    transition: all 1s ease 0s;
    &--hidden {
      animation: surfaceHidden 0.5s ease 0s forwards;
    }
    h1 {
      font-family: $fontBodoni;
      font-size: 11rem;
      font-weight: 600;
      line-height: 1.52;
      letter-spacing: -1.27px;
      color: $white;
      text-align: center;
      @media (max-width: $xxs) {
        font-size: 6.3rem;
        line-height: 1.3;
        letter-spacing: -0.73px;
      }
    }
    p {
      font-family: $fontOpenSans;
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.31;
      letter-spacing: 4.03px;
      color: $white;
      text-align: center;
      @media (max-width: $xxs) {
        font-size: 1.9rem;
        letter-spacing: 3.48px;
      }
    }
    &__footer {
      position: absolute;
      bottom: 6.8rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .button {
        min-width: 18rem;
        width: max-content;
        @media (max-width: $xxs) {
          min-width: 14.8rem;
        }
      }
      &__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &__line {
        height: 1px;
        max-width: 8rem;
        min-width: 2.7rem;
        width: 5vw;
        background-color: $white;
      }
    }
  }
}
@keyframes surfaceHidden {
  form {
    opacity: 1;
  }
  99% {
    z-index: auto;
  }
  to {
    opacity: 0;
    z-index: -1;
  }
}
</style>