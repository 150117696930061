import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import auth from "./modules/auth";
import user from "./modules/user";
import artworks from "./modules/artworks";
import artists from "./modules/artists";

export default new Vuex.Store({
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    artworks,
    artists
  },
});
