<template>
  <div class="form-control">
    <textarea required class="form-control__textarea" />
    <label class="form-control__label">{{ placeholder }}</label>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: { type: String },
  },
  data() {
    return {
      showText: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  margin-top: 10px;
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: rgba($color: $lighBlack, $alpha: 0.7);
    top: 25%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__textarea {
    min-height: 14.5rem;
    padding: 2.4rem 2.4rem 2.4rem 2.7rem;
    border: 0.5px solid $lighBlack;
    border-radius: 12px;
    @include type();
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__textarea:focus ~ &__label,
  &__textarea:valid ~ &__label {
    top: 0;
  }
}
</style>