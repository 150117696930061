<template>
  <div class="form-control">
    <div
      class="form-control__select"
      :class="{ 'form-control__select--placeholder': !selected }"
      @click="visible = !visible"
    >
      <label
        class="form-control__select_label"
        :class="{ 'form-control__select_label--selected': selected }"
        >{{ placeholder }}</label
      >
      <span class="text">{{ selected }}</span>
      <span class="icon" :class="{ 'icon--open': !disabled ? visible : false }">
        <IconArrowTop></IconArrowTop>
      </span>
    </div>
    <div
      class="form-control__items"
      :class="{ 'form-control__items--visible': !disabled ? visible : false }"
    >
      <div class="form-control__items_body">
        <ul>
          <li v-for="i in items" :key="i.key">
            <a
              :class="{ active: selected === i.title }"
              @click.prevent="
                $emit('action', {
                  key: i.key,
                  title: i.title,
                });
                visible = false;
              "
              >{{ i.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
export default {
  props: {
    items: { type: Array },
    selected: { type: [String, Number], required: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  components: { IconArrowTop },
  data() {
    return {
      visible: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  &__select {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9rem;
    color: $lighBlack;
    background: #ffffff;
    border: 1px solid $lighBlack;
    border-radius: 12px;
    padding-left: 2.7rem;
    padding-right: 2.4rem;
    @include type();
    transition: all 0.3s ease;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_label {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      @include type($fw: 500);
      padding: 1px 1.1rem;
      background-color: $white;
      transition: all 0.1s ease;
      pointer-events: none;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
      &--selected {
        top: 0;
      }
    }
    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
    }
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $red;
      color: $red;
    }
  }
  .icon {
    display: inline-flex;
    width: 1.85rem;
    height: 1.85rem;
    transition: all 0.3s ease-in-out;
    transform: rotate(-180deg);
    &--open {
      transform: rotate(0);
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 14px;
      height: 14px;
    }
  }
  &__items {
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 2;
    padding: 10px 15px;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      padding: 5px 0;
      border-radius: 12px;
      box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
      ul {
        padding: 0px 5px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        li {
          a {
            display: block;
            padding: 12px 15px;
            @include type();
            background-color: #fff;
            cursor: pointer;
            border-radius: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover,
            &.active {
              background-color: $blueLight;
              color: $blue;
            }
            @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>