<template>
  <button class="button" @click.prevent="$emit('click')">
    <slot></slot>
    <span v-if="text" class="button__text">{{ text }}</span>
  </button>
</template>
<script>
export default {
  props: {
    text: { type: String, required: false },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $lightGrey;
  border-radius: 7px;
  background-color: transparent;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba($color: $lightGrey, $alpha: 0.2);
  }
  svg {
    width: auto;
    height: auto;
    &::v-deep g {
      fill: $black;
    }
  }
  &__text {
    font-weight: 600;
    margin-left: 10px;
  }
  &--auto {
    width: auto;
    height: 5.5rem;
    padding: 0 2.2rem;
  }
  &--lg {
    height: 7.5rem;
    width: 7.5rem;
    border-radius: 12px;
  }
  &--md {
    height: 5.5rem;
    width: 5.5rem;
  }
  &--sm {
    height: 4.4rem;
    width: 4.4rem;
  }
  &--xsm {
    height: 3.4rem;
    width: 3.4rem;
  }
  &--uppercase {
    text-transform: uppercase;
  }
}
</style>