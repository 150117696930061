<template>
  <div class="autocomplete-input">
    <div
      class="autocomplete-input__input"
      :class="{ 'autocomplete-input__input--loading': loading }"
    >
      <input
        required
        type="text"
        v-model="item"
        @keyup.prevent="$emit('searchMatches', item)"
        @focus="focusInput = true"
        @blur="focusInput = false"
        :disabled="disabled"
      />
      <label class="autocomplete-input__label">{{ placeholder }}</label>
      <IconLoader class="autocomplete-input__icon" v-if="loading"></IconLoader>
    </div>

    <div class="autocomplete-input__match" v-if="matches.length && open">
      <p class="autocomplete-input__match_title">Search {{ placeholder }}</p>
      <button
        v-for="match in matches"
        :key="match.id"
        @click.prevent.stop="$emit('selectMatch', match)"
        class="autocomplete-input__match_item"
      >
        {{ match.title }}
      </button>
    </div>
  </div>
</template>
<script>
import IconLoader from "@/components/icons/IconLoader.vue";
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
    matches: {
      type: Array,
      default() {
        return [{ id: 1, title: "Title" }];
      },
    },
    disabled: { type: Boolean, default: false },
  },
  components: { IconLoader },
  data() {
    return {
      item: "",
      focusInput: false,
      open: false,
    };
  },
  created() {
    this.item = this.value;
  },
  watch: {
    value(val) {
      this.item = val;
    },
    focusInput(val) {
      if (!val) {
        this.item = "";
        this.$emit("selectMatch", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
  position: relative;
  width: 100%;
  padding-bottom: 11px;
  &__input {
    position: relative;
    margin-top: 10px;
    input {
      height: 9rem;
      padding: 0 2.4rem 0 2.7rem;
      border: 0.5px solid $lighBlack;
      border-radius: 12px;
      @include type();
      color: $lighBlack;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &--loading {
      input {
        padding-right: 4rem;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: rgba($color: $lighBlack, $alpha: 0.7);
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  input:focus ~ &__label,
  input:valid ~ &__label {
    top: 0;
  }

  &__match {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
    padding: 24px 22px 16px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #efefef;
    z-index: 2;
    &_title {
      @include type($fw: 700);
      text-transform: capitalize;
      margin-bottom: 25px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &_item {
      @include type();
      color: $white;
      text-align: center;
      border-radius: 8px;
      background-color: $greyDark;
      padding: 6px 12px;
      margin-bottom: 8px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $blueLight;
        color: $blue;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
}
</style>