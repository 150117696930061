<template>
  <div class="share__wrapper">
    <IconShare class="share__icon" />
    <div class="share__icons-box">
      <IconLink class="share__icons-box_icon" />
      <IconInstagram class="share__icons-box_icon" />
      <IconFacebook class="share__icons-box_icon" />
    </div>
  </div>
</template>

<script>
import IconShare from "@/components/icons/IconShare.vue";
import IconLink from "@/components/icons/IconLink.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";

export default {
  components: { IconShare, IconLink, IconInstagram, IconFacebook },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.share {
  &__wrapper {
    width: auto;
    display: inline-block;

    &:hover .share__icon {
      display: none;
    }
    &:hover .share__icons-box {
      display: flex;
    }
  }

  &__icon {
    display: block;
    width: 2.7rem;
    cursor: pointer;
  }

  &__icons-box {
    display: none;
    height: 3rem;

    &_icon {
      margin: 0px 1.5rem;
      cursor: pointer;
    }
  }
}
</style>
