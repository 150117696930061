import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import History from "@/views/History.vue";
import About from "@/views/About.vue";
import TheGrottaHomeBook from "@/views/books/TheGrottaHomeBook.vue";

import admin from "./admin.js";
import exteriors from "./exteriors.js";

Vue.use(VueRouter);

const routes = [
  ...admin,
  ...exteriors,
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "base",
      footer: false,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/the-grotta-home-book",
    name: "TheGrottaHomeBook",
    component: TheGrottaHomeBook,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.layout === "admin")) {
    let token = Vue.$cookies.get("token");
    if (token == null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
