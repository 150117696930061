import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './axios'
import "./registerServiceWorker";
import "./assets/style.scss";

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import AdminLayout from "@/layouts/AdminLayout.vue";
Vue.component("admin-layout", AdminLayout);
import BaseLayout from "@/layouts/BaseLayout.vue";
Vue.component("base-layout", BaseLayout);
import EmptyLayout from "@/layouts/EmptyLayout.vue";
Vue.component("empty-layout", EmptyLayout);

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  containerClass: 'toasted-container--collect',
  position: 'top-center',
  duration: '1500',
  keepOnHover: true,
})

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2, {
  showCloseButton: true,
  customClass: {
    container: "notification",
    popup: "notification__popup",
    confirmButton: "btn",
    cancelButton: "btn",
    denyButton: 'btn'
  },
});

import "./validate.js";
import { ValidationProvider } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
