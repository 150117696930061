<template>
  <div class="symbol"></div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  height: 19.5rem;
  width: 19.5rem;
  border-radius: 50%;
  border: 3.5rem solid $red;
  border-bottom-color: transparent;
  opacity: 0.6;
  transform: rotate(-45deg);
}

@media only screen and (max-width: $xxxs) {
  .symbol {
    height: 11rem;
    width: 11rem;
    border-radius: 50%;
    border: 2rem solid $red;
    border-bottom-color: transparent;
    opacity: 0.6;
    transform: rotate(-45deg);
  }
}
</style>
