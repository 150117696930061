var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"art",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"art__header"},[_c('h3',{staticClass:"art__header_title"},[_vm._v("Add Art")]),_c('div',{staticClass:"art__header_actions"},[_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete Artist"},on:{"click":_vm.removeArtist}}),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","disabled":invalid},on:{"click":_vm.saveArtist}})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Basic Information")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Fist Name","validateError":errors[0],"element":_vm.artist.first_name},on:{"input":function($event){_vm.artist.first_name = $event}}})]}}],null,true)}),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseInput',{attrs:{"placeholder":"Last Name","element":_vm.artist.last_name},on:{"input":function($event){_vm.artist.last_name = $event}}})],1)],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Path","validateError":errors[0],"element":_vm.artist.slug},on:{"input":function($event){_vm.artist.slug = $event}}}),_c('span',{staticClass:"simple-text"},[_vm._v("Example: "+_vm._s((_vm.url + "/artists/" + (_vm.artist.slug || "path"))))])]}}],null,true)}),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseSelect',{attrs:{"placeholder":"Status","selected":_vm.artist.status,"items":[
            { key: '0', title: 'Active' },
            { key: '1', title: 'Inactive' } ]},on:{"action":function($event){_vm.artist.status = $event.key}}})],1)],1),_c('div',{staticClass:"form__row"},[_c('MultipleAutocompleteInput',{attrs:{"loading":false,"placeholder":"Tag"}})],1),_c('div',{staticClass:"form__row form__row--bordered"},[_c('UploadImage')],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Biography")]),_c('div',{staticClass:"form__row"},[_vm._v("EDITOR")])]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Connections")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('AutocompleteInput',{attrs:{"loading":false,"placeholder":"Artist"}})],1)]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('BaseSelect',{attrs:{"placeholder":"Status","items":[
            { key: 'book1', title: 'Book 1' },
            { key: 'book2', title: 'Book 2' } ]}})],1)]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('AutocompleteInput',{attrs:{"loading":false,"placeholder":"Artist"}})],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }