import General from "@/views/admin/General.vue";
import Collection from "@/views/admin/Collection.vue";
import Artwork from "@/views/admin/Artwork.vue";
import Artists from "@/views/admin/Artists.vue";

export default [
    {
        path: "/admin",
        redirect: '/admin/collection',
        component: General,
        children: [
            {
                path: "collection",
                redirect: '/admin/collection/artwork',
                name: "Collection",
                component: Collection,
                meta: {
                    layout: "admin",
                },
                children: [
                    {
                        path: "artwork",
                        name: "Artwork",
                        component: Artwork,
                        meta: {
                            layout: "admin",
                        },
                    },
                    {
                        path: "artists",
                        name: "Artists",
                        component: Artists,
                        meta: {
                            layout: "admin",
                        },
                    }
                ],
            },
        ]
    },
]