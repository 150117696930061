<template>
  <div class="history">
    <div class="history__header">
      <div class="temp-top-bar-imitator"></div>
      <img
        class="history__header_background mobile"
        src="@/assets/images/history/history-main-photo-mobile.png"
        alt="Grotta House History"
      />
      <img
        class="history__header_background"
        src="@/assets/images/history/history-main-photo.png"
        alt="Grotta House History"
      />
      <div class="history__header_titles">
        <h2>Chapter: The History</h2>
        <h1>Grotta House Story</h1>
        <div class="symbol-box">
          <span>scroll to discover</span>
          <RedSymbol class="symbol" />
        </div>
      </div>
    </div>

    <div class="history__body">
      <section class="sketch__container">
        <div class="description-box">
          <span
            >Built during the latter part of the 1980s, The Grotta House sits amid seven acres of
            rural landscape in Harding Township, New Jersey.</span
          >
        </div>
        <YearLine year="1980" position="right" />
        <img
          src="@/assets/images/history/original-sketch-1.png"
          alt="Grotta residence hand sketch"
        />
        <span class="image-description">Grotta residence original hand sketch</span>
        <div class="top-views__box">
          <img
            class="top-views__image"
            src="@/assets/images/history/original-sketch-2.png"
            alt="Grotta residence sketch"
          />
          <img
            class="top-views__image"
            src="@/assets/images/history/original-sketch-3.png"
            alt="Grotta residence sketch"
          />
        </div>
        <span class="sketch__description"
          >Two perpendicular axes extend from the center of the house to distant locations, thereby
          extending the interior volumes into the landscape and according the house a locus within
          an otherwise ill-defined site.</span
        >
      </section>

      <YearLine class="year-line" year="1981" />
      <section class="building__container">
        <img src="@/assets/images/history/building.png" alt="Grotta residence building" />
        <span class="image-description">Lou Grotta, Sandy Grotta, Tracy Tell</span>
      </section>

      <section class="plan__container">
        <div class="description-box">
          <span
            >Much like it does with its rural surroundings, the house features curved windows and
            smooth cylinder and rectangular structures that offer a simplistic backdrop for the
            uniquely crafted vases and vessels on display, enhancing each art form’s ability to
            create new dimensions and open spaces.
          </span>
        </div>
        <div class="plan__image-box">
          <RedSymbol class="plan__image-box_symbol" />
          <img
            class="plan__image-box_image"
            src="@/assets/images/history/plan.png"
            alt="Grotta residence drawing"
          />
        </div>
        <span class="plan__description"
          >In The Grotta House one encounters vases, bowls, baskets, chairs, textiles, and teapots,
          but also powerful sculptural forms, rich with an array of nature-derived or
          nature-inspired textures, colors, and shapes.</span
        >
      </section>

      <YearLine class="year-line" year="1989" />
      <section class="finished__container">
        <img src="@/assets/images/history/finished.png" alt="Grotta residence" />
        <span class="image-description">Lou Grotta, Richard Meier</span>
      </section>

      <section class="architect__container">
        <div class="description-box">
          <span
            >Built by renowned architect Richard Meier, the house was geometrically designed to
            harmonize with its natural surroundings and embrace the nearby forest and slope terrain
            to anchor its base and create fluidity throughout its interior and exterior
            spaces.</span
          >
        </div>
        <div class="button-box">
          <BaseButton
            class="button--black button--oval"
            text="Richard Meier website"
            @click.native="followLink"
          />
          <RedSymbol />
        </div>
      </section>

      <div class="navigation__container">
        <span class="navigation__container_title">Next Chapter: The Interiors</span>
        <div class="navigation__container_buttons-box">
          <BaseButton
            class="button--black button--oval"
            text="Exteriorse"
            @click.native="goToPage()"
          />
          <BaseButton
            class="button--black button--oval"
            text="Interiors"
            @click.native="goToPage()"
          />
          <BaseButton
            class="button--outline button--oval"
            text="Back to Home"
            @click.native="goToPage('Home')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RedSymbol from "@/components/history/HistoryRedC.vue";
import YearLine from "@/components/history/HistoryYearLine.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    RedSymbol,
    YearLine,
    BaseButton,
  },

  computed: {},

  methods: {
    followLink() {
      window.open("https://meierpartners.com/", "_blank");
    },

    goToPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.7rem;
  line-height: 3.6rem;

  .history__header {
    &_background {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;

      &.mobile {
        display: none;
      }
    }

    &_titles {
      position: relative;
      align-items: flex-start;
      margin: 79rem 16.4rem 17rem;
      line-height: 4.4rem;

      h1 {
        color: $white;
        font-size: 6.2rem;
        line-height: 6.8rem;
        margin-top: 2rem;
      }

      h2 {
        color: $white;
        font-size: 3.1rem;
      }

      .symbol-box {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 9rem;
        width: 100%;

        span {
          color: $white;
          font-size: 1.8rem;
        }

        .symbol {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .history__body {
    .year-line {
      margin-top: 10rem;
    }

    section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem 16.4rem;

      .description-box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .image-description {
        font-size: 2.2rem;
      }

      &.sketch__container {
        .description-box {
          span {
            width: 52%;
            margin-bottom: 3.3rem;
          }
        }

        img {
          width: 100%;
        }

        .top-views__box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 12.3rem;

          .top-views__image {
            height: 58.1rem;
            width: 66.6rem;
          }

          :last-child {
            margin-top: 9.5rem;
          }
        }

        .sketch__description {
          display: inline-block;
          text-align: center;
          margin-top: 14rem;
          width: 65%;
        }
      }

      &.building__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          position: absolute;
          width: 100%;
        }

        .image-description {
          margin-top: 127.4rem;
        }
      }

      &.plan__container {
        margin-top: 11.4rem;

        .description-box {
          span {
            width: 58%;
          }
        }

        .plan__image-box {
          position: relative;
          height: 57.6rem;
          width: 100%;

          &_symbol {
            position: absolute;
            left: 0;
            bottom: 7rem;
          }

          &_image {
            position: absolute;
            top: -12rem;
            right: 7rem;
          }
        }

        .plan__description {
          display: inline-block;
          text-align: center;
          width: 65%;
        }
      }

      &.finished__container {
        img {
          position: absolute;
          width: 100%;
        }

        .image-description {
          margin-top: 143.6rem;
        }
      }

      &.architect__container {
        margin-top: 9.8rem;

        .description-box {
          span {
            width: 60%;
          }
        }

        .button-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    }

    .navigation__container {
      display: flex;
      flex-direction: column;
      margin: 7.5rem 0rem 10.4rem;
      padding-left: 25%;

      &_title {
        font-size: 3.8rem;
        line-height: 4.8rem;
        margin: 0rem 0rem 5rem 1.2rem;
      }

      &_buttons-box {
        display: flex;
        align-items: center;

        &::after {
          content: "";
          background-color: $black;
          height: 1px;
          flex-grow: 100;
        }

        .button--oval {
          margin-left: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $xxxs) {
  .history {
    font-size: 1.8rem;
    line-height: 2.8rem;

    //
    .temp-top-bar-imitator {
      margin-top: 5.6rem;
    }
    //

    .history__header {
      &_background {
        display: none;

        &.mobile {
          display: block;
        }
      }

      &_titles {
        margin: 41.3rem 2.8rem 14rem;

        h1 {
          font-size: 3.5rem;
          font-weight: 500;
          line-height: 4.5rem;
          margin-top: 0rem;
        }

        h2 {
          font-size: 2.3rem;
        }

        .symbol-box {
          margin-top: 4.7rem;

          span {
            font-size: 1.2rem;
          }

          .symbol {
            top: 3.5rem;
            right: 0;
          }
        }
      }
    }

    .history__body {
      .year-line {
        margin-top: 4rem;
      }

      section {
        padding: 0rem 2.8rem;

        .image-description {
          font-size: 1.2rem;
        }

        &.sketch__container {
          .description-box {
            span {
              width: 100%;
              margin-bottom: 3.3rem;
            }
          }

          .top-views__box {
            width: 100%;
            flex-direction: column;
            margin-top: 5.4rem;

            .top-views__image {
              height: 27.5rem;
              width: 100%;
            }

            :last-child {
              margin-top: 3.3rem;
            }
          }

          .sketch__description {
            margin-top: 6.9rem;
            width: 100%;
          }
        }

        &.building__container {
          img {
            width: calc(100% - 5.6rem);
          }

          .image-description {
            margin-top: 21.1rem;
          }
        }

        &.plan__container {
          margin-top: 6.9rem;

          .description-box {
            span {
              width: 100%;
            }
          }

          .plan__image-box {
            position: relative;
            height: 30.6rem;

            &_symbol {
              display: none;
            }

            &_image {
              top: 0rem;
              right: 0rem;
            }
          }

          .plan__description {
            width: 100%;
          }
        }

        &.finished__container {
          img {
            width: calc(100% - 5.6rem);
          }

          .image-description {
            margin-top: 23.8rem;
          }
        }

        &.architect__container {
          margin-top: 6.9rem;

          .description-box {
            span {
              width: 100%;
            }
          }

          .button-box {
            margin-top: 10rem;
            flex-direction: column;
            align-items: flex-start;

            .button--oval {
              margin-bottom: 8rem;
            }

            .symbol {
              margin-left: 65%;
            }
          }
        }
      }

      .navigation__container {
        margin: 7.6rem 0rem 8.9rem;
        padding-left: 2.6rem;

        &_title {
          font-size: 1.8rem;
          line-height: 2.8rem;
          margin: 0rem 0rem 3rem 1.4rem;
        }

        &_buttons-box {
          flex-wrap: wrap;

          &::after {
            margin-top: 16px;
          }

          .button--oval {
            margin-left: 12px;
          }

          .button--outline {
            margin-top: 1.7rem;
          }
        }
      }
    }
  }
}
</style>
