<template>
  <div class="table">
    <div
      class="table__inner"
      ref="table"
      @mousedown="
        isDown = true;
        mousedown($event);
      "
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousemove="mousemove($event)"
    >
      <div class="table__content">
        <header class="table__header">
          <div
            class="table__header_col"
            :class="`table__header_col--${item.className}`"
            v-for="(item, key) in headerItems"
            :key="`header-${key}`"
          >
            <p class="text">{{ item.title }}</p>
            <span class="icons" v-if="item.sortable">
              <span class="icon">
                <IconArrowTop></IconArrowTop>
              </span>
              <span class="icon icon--down">
                <IconArrowTop></IconArrowTop>
              </span>
            </span>
          </div>
        </header>
        <div class="table__body">
          <div class="table__body_row" v-for="(item, key) in items" :key="`row-${key}`">
            <div
              class="table__body_col"
              :class="`table__body_col--${el.class}`"
              v-for="(el, index) in columnNames"
              :key="`header-${index}`"
            >
              <template v-if="!el.variables">
                <p class="table__item">
                  {{ item[el.key] }}
                </p></template
              >
              <template v-else>
                <span
                  v-if="el.variables === 'icon'"
                  class="table__icon"
                  :class="`table__icon--${el.variables}`"
                >
                  <component :is="el.icon"></component>
                </span>
                <div
                  v-if="el.variables === 'tag'"
                  class="table__item"
                  :class="`table__item--${el.variables}`"
                >
                  <p :class="{ tag: item[el.key].main }">{{ item[el.key].main || "-" }}</p>
                  <AdditionalTags
                    v-if="item[el.key].additional.length"
                    :items="item[el.key].additional"
                    :parentClientRect="tableClientRect"
                    @getParentClientRect="getParentClientRect"
                  ></AdditionalTags>
                </div>
                <p
                  v-if="el.variables === 'status'"
                  class="table__item"
                  :class="`table__item--${el.variables}`"
                >
                  {{ item[el.key] }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import AdditionalTags from "@/components/AdditionalTags.vue";
export default {
  props: {
    loading: { type: Boolean, default: false },
    headerItems: Array,
    items: Array,
  },
  components: { IconArrowTop, IconEdit, AdditionalTags },
  data() {
    return {
      columnNames: [],
      tableClientRect: { bottom: 0, right: 0 },
      isDown: false,
    };
  },
  created() {
    const keyItems = [];
    this.headerItems.map(h => {
      if (h.key) {
        let el = {
          key: h.key,
          class: h.className,
          variables: h.variables ? h.variables : false,
          icon: h.icon,
        };
        keyItems.push(el);
      }
    });
    this.columnNames = keyItems;
  },
  mounted() {
    this.getParentClientRect();
  },
  watch: {
    items() {
      this.getParentClientRect();
    },
  },
  methods: {
    getParentClientRect() {
      this.tableClientRect = this.$refs.table.getBoundingClientRect();
    },
    mousedown(e) {
      let tableScrolled = this.$refs.table;
      this.startX = e.pageX - tableScrolled.offsetLeft;
      this.scrollLeft = tableScrolled.scrollLeft;
    },
    mousemove(e) {
      let tableScrolled = this.$refs.table;
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - tableScrolled.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      tableScrolled.scrollLeft = this.scrollLeft - walk;
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  border: 1px solid rgba($color: #979797, $alpha: 0.3);
  border-radius: 16px;
  padding-bottom: 20px;
  background-color: $white;
  flex: 1;
  &__inner {
    height: 100%;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    padding-bottom: 20px;
  }
  &__content {
    width: auto;
    position: relative;
    margin-bottom: 0;
    overflow: initial;
    padding: 0;
    flex: 1;
  }
  &__header,
  &__body {
    min-width: 100%;
    &_row {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      min-width: 100%;
      transition: all 0.2s;
      padding: 0 28px;
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 2.8rem;
        height: 1px;
        width: calc(100% - 5.6rem);
        background-color: rgba($color: #979797, $alpha: 0.3);
      }
    }
    &_col {
      display: flex;
      align-items: center;
      position: relative;
      padding: 2.5px 4px;
      flex: 1;
      min-width: 164px;
      box-sizing: content-box;
      &--lg {
        min-width: 230px;
      }
      &--md {
        min-width: 152px;
      }
      &--sm {
        min-width: 124px;
      }
      &--xsm {
        min-width: 54px;
        max-width: 54px;
      }
      &--capitalize {
        .table__item {
          text-transform: capitalize;
        }
      }
    }
  }
  &__header {
    display: flex;
    height: 6.3rem;
    padding: 0 28px;
    border-bottom: 1px solid rgba($color: #979797, $alpha: 0.3);
    &_col {
      .text {
        @include type($fw: 500);
        color: rgba($color: #000000, $alpha: 0.7);
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
      .icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        .icon {
          display: inherit;
          margin: 2px 0;
          cursor: pointer;
          color: red;
          &--down {
            transform: rotate(180deg);
          }
          &::v-deep g {
            fill: rgba($color: $black, $alpha: 0.5);
          }
          &:hover {
            &::v-deep g {
              fill: rgba($color: $black, $alpha: 1);
            }
          }
        }
      }
    }
  }
  &__body {
    min-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    &_row {
      height: 8.4rem;
    }
  }
  &__item {
    @include type($fw: 500);
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &--tag {
      display: flex;
      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        padding: 6px 10px;
        margin-right: 10px;
        &.tag {
          border-radius: 6px;
          color: $blue;
          background-color: $blueLight;
        }
      }
    }
    &--status {
      position: relative;
      padding: 0 0 0 30px;
      text-transform: capitalize;
      &:before {
        display: inline-block;
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: $orange;
      }
    }
  }
  &__icon {
    cursor: pointer;
    &::v-deep g {
      fill: $black;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::v-deep g {
        fill: $orange;
      }
    }
  }
}
</style>