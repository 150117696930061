<template>
  <transition name="fade" appear>
    <div class="modal" v-if="show" :style="{ backgroundColor: bgColor }">
      <div class="modal___background" @click="$emit('hideModal')"></div>
      <IconButton class="modal__close button--lg" @click="$emit('hideModal')">
        <IconClose width="20" height="20"></IconClose>
      </IconButton>
      <transition name="pop" appear>
        <div class="modal__content" v-if="show">
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  name: "modal",
  props: {
    bgColor: { type: String, default: "#fff" },
    show: { type: Boolean, default: false },
  },
  components: { IconButton, IconClose },
};
</script>
<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 1;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer;
  }
  &__content {
    position: relative;
    width: 100%;
    width: 95rem;
    height: 100%;
    max-width: 90vw;
    z-index: 999;
    padding: 5.5rem 0;
    overflow: hidden;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      padding: 20px 0;
    }
  }
  &__close {
    position: absolute;
    right: 4rem;
    top: 4rem;
    z-index: 9;
  }
}

/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  transition-delay: 0.3s;
}

.pop-enter-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.3s linear;
}
.pop-leave-active {
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.5) translateY(-50%, -50%);
}
</style>