<template>
  <div class="page">
    <header class="header">
      <div class=""></div>
      <div class="menu">
        <IconButton class="menu__button button--lg">
          <IconSearch></IconSearch>
        </IconButton>
        <IconButton class="menu__button button--lg">
          <IconMenu></IconMenu>
        </IconButton>
      </div>
    </header>
    <slot></slot>
    <footer class="footer" v-if="footer">
      <div class="footer__block-text">
        <a class="footer__text" href="email:info@grottahouse.com">info@grottahouse.com</a>
        <a class="footer__text" href="">Terms & Conditions</a>
        <a class="footer__text" href="">Privacy Policy</a>
      </div>
      <p class="footer__copyright">
        © <span class="footer__copyright footer__copyright__author">2021 Grotta House</span>
      </p>
      <div class="footer__social">
        <a class="footer__social__link" href="https://www.instagram.com/">
          <img
            class="footer__social__icon"
            src="@/assets/images/instagram_icon.svg"
            alt="instagram icon"
          />
        </a>
        <a class="footer__social__link" href="https://www.facebook.com/">
          <img
            class="footer__social__icon"
            src="@/assets/images/facebook_icon.svg"
            alt="facebook icon"
          />
        </a>
        <a class="footer__social__link" href="https://www.twitter.com/">
          <img
            class="footer__social__icon"
            src="@/assets/images/twitter_icon.svg"
            alt="twitter icon"
          />
        </a>
        <a class="footer__social__link" href="https://www.youtube.com/">
          <img
            class="footer__social__icon"
            src="@/assets/images/youtube_icon.svg"
            alt="youtube icon"
          />
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconMenu from "@/components/icons/IconMenu.vue";

export default {
  components: {
    IconButton,
    IconSearch,
    IconMenu,
  },
  computed: {
    footer() {
      return this.$route.meta.footer ?? false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background-color: $white;
  .header {
    position: fixed;
    z-index: 999;
    justify-content: space-between;
    top: 0;
    right: 0;
    padding: 50px 50px 10px 50px;
    @media (max-width: $xxs) {
      padding: 40px 30px 10px 30px;
    }
  }
  .menu {
    display: flex;
    &__button {
      border: none;
      border-radius: 50%;
      background-color: $white;
      &:hover {
        background-color: rgba($color: $light2Grey, $alpha: 1);
      }
      @media (max-width: $xxs) {
        height: 6rem;
        width: 6rem;
      }
    }
    .button:first-of-type {
      margin-right: 4rem;
      @media (max-width: $xxs) {
        margin-right: 1.5rem;
      }
    }
  }
  .footer {
    position: relative;
    width: 100%;
    padding: 20px 50px;
    background-color: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: $xxs) {
      padding: 20px 25px;
    }
    &__copyright {
      font-family: $fontOpenSans;
      color: $white;
      font-size: 3rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      @media (max-width: $xxs) {
        font-size: 2.8rem;
      }
      @media (max-width: $xxxs) {
        font-size: 2.5rem;
      }
      &__author {
        width: max-content;
        font-size: 1.6rem;
        letter-spacing: -0.3px;
        line-height: 2.75;
        transition: all 0.5s ease;
        padding-left: 15px;
        @media (max-width: $xxs) {
          padding-left: 10px;
          font-size: 1.5rem;
        }
        @media (max-width: $xxxs) {
          padding-left: 5px;
          font-size: 1.4rem;
        }
      }
    }
    &__block-text {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: $xxs) {
        position: relative;
      }
    }
    &__text {
      font-family: $fontOpenSans;
      color: $white;
      font-size: 1.6rem;
      letter-spacing: -0.3px;
      line-height: 2.75;
      text-decoration: none;
      margin: 0 40px;
    }
    &__social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      &__link {
        margin-left: 30px;
        @media (max-width: $xxs) {
          margin-left: 25px;
        }
        @media (max-width: $xxxs) {
          margin-left: 20px;
        }
        &:first-of-type {
          margin-left: 0;
        }
      }
      &__icon {
        width: 30px;
        height: 25px;
        @media (max-width: $xxs) {
          width: 25px;
          height: 20px;
        }
      }
    }
  }
}
</style>